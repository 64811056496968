import React from "react";
import theme from "theme";
import { Theme, Box, Text, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Hakkımızda | Pyrvo Rentals'ı Keşfedin
			</title>
			<meta name={"description"} content={"Her sürüşün kentsel bir maceraya dönüştüğü Pyrvo Rentals ile kentsel keşif ritminin kilidini açın"} />
			<meta property={"og:title"} content={"Hakkımızda | Pyrvo Rentals'ı Keşfedin"} />
			<meta property={"og:description"} content={"Her sürüşün kentsel bir maceraya dönüştüğü Pyrvo Rentals ile kentsel keşif ritminin kilidini açın"} />
			<meta property={"og:image"} content={"https://pyrvo.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://pyrvo.com/img/a463247.jpeg"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://pyrvo.com/img/a463247.jpeg"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://pyrvo.com/img/a463247.jpeg"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://pyrvo.com/img/a463247.jpeg"} />
			<link rel={"apple-touch-startup-image"} href={"https://pyrvo.com/img/a463247.jpeg"} />
			<meta name={"msapplication-TileImage"} content={"https://pyrvo.com/img/a463247.jpeg"} />
		</Helmet>
		<Components.Header />
		<Section padding="0 0 0 0" quarkly-title="Content-6">
			<Override
				slot="SectionContent"
				lg-padding="0px 0 0px 0px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				width="100%"
				min-width="100%"
				lg-grid-template-columns="1fr"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				background="rgba(0, 0, 0, 0) url(https://pyrvo.com/img/3.jpg) 50% 50% /cover repeat scroll padding-box"
				lg-height="600px"
				md-height="500px"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				flex-direction="column"
				padding="80px 60px 80px 60px"
				sm-padding="50px 25px 80px 25px"
			>
				<Text margin="0px 0px 30px 0px" font="normal 600 42px/1.2 --fontFamily-sans">
					Hakkımızda
				</Text>
				<Text margin="0px 0px 25px 0px" font="normal 300 18px/1.2 --fontFamily-sans" color="#505257">
					Pyrvo Rentals ile kendinizi şehrin kalp atışlarına bırakın. Bir kiralama hizmetinden daha fazlası olarak, şehir içi sürüş deneyiminizi yükseltmek için kolaylık, stil ve güvenilirliğin kusursuz bir karışımını sunarak şehir ormanında gezinme konusunda ortaklarınızız.
				</Text>
				<Text margin="0px 0px 20px 0px" font="normal 500 25px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
					Pyrvo'nun Nabzı
				</Text>
				<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sans" color="#505257">
					Pyrvo Rentals olarak hikayemiz, kentsel yaşama duyduğumuz tutku ve bireyleri şehir hayatının canlılığını kucaklamaları için güçlendirme taahhüdümüzle başlıyor. Şehir keşfinin benzersiz taleplerini anlıyor ve şehir sokaklarının enerjisini ve dinamizmini yansıtan bir sürüş deneyimi sunmak için çalışıyoruz.
				</Text>
			</Box>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-7"
		>
			<Override
				slot="SectionContent"
				flex-direction="column"
				sm-min-width="280px"
				lg-flex-wrap="wrap"
				display="flex"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 64px 0px"
			>
				<Text
					as="h2"
					margin="0px 0px 24px 0px"
					font="--headline2"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					Özümüz Neden Önemlidir?
				</Text>
			</Box>
			<Box
				width="100%"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="48px"
				lg-grid-gap="48px"
				lg-width="100%"
				sm-grid-template-columns="1fr"
				lg-grid-template-columns="repeat(2, 1fr)"
			>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px">
						Kentsel Uzmanlık
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
						Şehir dinamiklerini derinlemesine anlayarak, hizmetlerimizi şehir sakinlerinin ihtiyaçlarını karşılayacak şekilde uyarlıyor, verimli ve sorunsuz hareketlilik sağlıyoruz.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
						Şehir Merkezli Misafirperverlik
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
						Müşteri hizmetlerimizden araç seçimine kadar Pyrvo'nun her yönü, şehir misafirperverliğinin özünü temsil eder ve her etkileşimi sorunsuz ve keyifli bir deneyim haline getirir.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
						Verimlilik ve Stil
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
						Filomuz sadece işlevsellik için değil, aynı zamanda sofistike olmak için de seçilmiştir ve şehirde güvenle ve tarzla gezinmenizi sağlar.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section background="--color-darkL2" padding="60px 0" sm-padding="40px 0">
			<Box
				margin="-16px -16px -16px -16px"
				padding="0px 0px 0px 0px"
				display="flex"
				width=" "
				flex-wrap="wrap"
			>
				<Box padding="16px 16px 16px 16px" display="flex" width="50%" lg-width="100%">
					<Box
						padding="98px 64px 98px 64px"
						display="flex"
						flex-direction="column"
						background="--color-light"
						color="--dark"
					>
						<Text
							as="h4"
							margin="12px 0"
							font="--base"
							color="--grey"
							letter-spacing="1px"
							text-transform="uppercase"
						>
							Pyrvo Rentals'ta bize katılın ve şehrin hareketli sokaklarında ve gizli mücevherlerinde bir yolculuğa çıkın. Tekerleğin her dönüşünde, şehir destanınızda yeni bir bölüm yazın ve sizi bekleyen sonsuz olasılıkları keşfedin.
						</Text>
						<Text as="h2" margin="12px 0" font="--headline2" md-font="--headline3">
							Pyrvo Rentals - Her Sokağın Yazılmayı Bekleyen Bir Hikaye Olduğu Yer
						</Text>
					</Box>
				</Box>
				<Box display="flex" width="50%" flex-wrap="wrap" lg-width="100%">
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="100%"
						lg-width="33.3333%"
						md-width="100%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 624px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://pyrvo.com/img/4.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="50%"
						lg-width="33.3333%"
						md-width="50%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 296px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://pyrvo.com/img/5.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="50%"
						lg-width="33.3333%"
						md-width="50%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 296px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://pyrvo.com/img/6.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});